import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import { ButtonPrimary } from '../../../../../components';
import { ButtonDropdown } from '../ButtonDropdown';
import useAppyPricesButton from './useAppyPricesButton';

const AppyPricesButton = () => {
    const { isDisabled, isDisabledOption, placeholder, handleApplyPrices } = useAppyPricesButton();

    const datapointExhibitionType = useSelector(
        (state: RootState) => state.datapointExhibitionType.datapointExhibitionType,
    );

    return (
        <>
            {datapointExhibitionType === 'FAMILY' ? (
                <ButtonPrimary disabled={isDisabled} onClick={() => handleApplyPrices(datapointExhibitionType === 'FAMILY')} >
                    Aplicar preço
                </ButtonPrimary>
            ) : (
                <ButtonDropdown
                    disabled={isDisabled}
                    onClick={() => handleApplyPrices(datapointExhibitionType === 'PRODUCT' ? false : true)}
                    onSelectOption={(itemId) => {
                        if (itemId === 'APPLY_TO_FAMILY') {
                            handleApplyPrices(datapointExhibitionType === 'PRODUCT');
                        }
                    }}
                    skin="blue"
                    options={[
                        {
                            id: 'APPLY_TO_FAMILY',
                            label: 'Aplicar na Família',
                            value: 'APPLY_TO_FAMILY',
                            disabled: isDisabledOption,
                            placeholder,
                        },
                    ]}
                >
                    Aplicar preço
                </ButtonDropdown>
            )}
        </>
    );
};

export default memo(AppyPricesButton);
